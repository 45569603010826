import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  SET_PDF_MIME_TYPE,
  SET_PNG_MIME_TYPE,
  SET_LOGO_MIME_TYPE,
  SET_PDF_SUCCESS,
  SET_PNG_SUCCESS,
  SET_LOGO_SUCCESS,
  SET_PDF_ITEM,
  SET_PNG_ITEM,
  SET_LOGO_ITEM,
  SET_PDF_FILENAME,
  SET_PNG_FILENAME,
  SET_LOGO_FILENAME,
  SET_DRIVING_SCHOOL_SWITCHES,
  SET_SIGNATURE_STATUSES,
  SET_QR_CODE_URL,
  SET_DRIVE_BUZZ_ONBOARDING_LINKS,
  SET_COURSE_QR_CODE_URL,
  SET_DRIVING_SCHOOL_IS_USING_MIRROR_VEHICLE,
} from "@/store/DrivingSchool/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_PDF_MIME_TYPE](state: State, pdfMimeType: string) {
    Object.assign(state, { pdfMimeType });
  },

  [SET_PNG_MIME_TYPE](state: State, pngMimeType: string) {
    Object.assign(state, { pngMimeType });
  },

  [SET_LOGO_MIME_TYPE](state: State, logoMimeType: string) {
    Object.assign(state, { logoMimeType });
  },

  [SET_PDF_SUCCESS](state: State, pdfSuccess: any) {
    Object.assign(state, { pdfSuccess });
  },

  [SET_PNG_SUCCESS](state: State, pngSuccess: any) {
    Object.assign(state, { pngSuccess });
  },

  [SET_LOGO_SUCCESS](state: State, logoSuccess: any) {
    Object.assign(state, { logoSuccess });
  },

  [SET_PDF_ITEM](state: State, pdfItem: any) {
    Object.assign(state, { pdfItem });
  },

  [SET_PNG_ITEM](state: State, pngItem: any) {
    Object.assign(state, { pngItem });
  },

  [SET_LOGO_ITEM](state: State, logoItem: any) {
    Object.assign(state, { logoItem });
  },

  [SET_PDF_FILENAME](state: State, pdfFileName: any) {
    Object.assign(state, { pdfFileName });
  },

  [SET_PNG_FILENAME](state: State, pngFileName: any) {
    Object.assign(state, { pngFileName });
  },

  [SET_LOGO_FILENAME](state: State, logoFileName: any) {
    Object.assign(state, { logoFileName });
  },

  [SET_DRIVING_SCHOOL_SWITCHES](state: State, drivingSchoolSwitches: any) {
    Object.assign(state, { drivingSchoolSwitches });
  },
  [SET_SIGNATURE_STATUSES](state: State, payload: any) {
    state.signatureStatuses = payload;
  },
  [SET_QR_CODE_URL](state: State, payload: any) {
    state.qrCodeUrl = payload;
  },
  [SET_DRIVE_BUZZ_ONBOARDING_LINKS](state: State, payload) {
    state.driveBuzzOnboardingLinks = payload;
  },
  [SET_COURSE_QR_CODE_URL](state: State, payload: any) {
    state.qrCodeUrlCourse = payload;
  },
  [SET_DRIVING_SCHOOL_IS_USING_MIRROR_VEHICLE](state: State, payload: any) {
    state.drivingSchoolIsUsingMirrorVehicles = payload;
  },
};
